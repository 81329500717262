import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import Swal from 'sweetalert2'
import imgError from '@/assets/images/error.png'

Vue.use(Vuex)

const store = {
   state: {
      // urlRest: 'http://10.1.1.218:8085/Carolina-Erp-Adm-0.0.1/',
      urlRest: 'https://carolina.app.br/Carolina-Erp-Adm/',
      isCarregando: false,
      urlAjuda: 'https://carolina.app.br/help.html',
      estados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
      isLogado: false,
      dadosUsuario: null,
      tokenSessao: null,
      tabs: []
   },
   mutations: {
      /* eslint-disable-next-line no-unused-vars */
      imageError : (state, e) => {
         e.target.src = imgError
      },
      addTabData : (state, route) => {
         let index = state.tabs.findIndex(tab => tab.id == route.id)
         // console.log('NEW Size: '+ state.tabs.length +' / '+ 'idTab: '+ route.id)
         
         if (index == -1) {
            state.tabs.push(route)
         }
      },
      attTabData : (state, route) => {
         let index = state.tabs.findIndex(tab => tab.id == route.id)
         // console.log('ATT Size: '+ state.tabs.length +' / '+ 'idTab: '+ route.id)

         if (index != -1) {
            state.tabs[index].data = route.data
            Vue.prototype.$set(state.tabs, index, state.tabs[index])
         }
      },
      deleteTabData : (state, index) => {
         // console.log('DEL Size: '+ state.tabs.length +' / '+ 'index: '+ index)
         state.tabs.splice(index, 1)

         if (state.tabs.length > 0) {
            state.tabs.forEach((tab, index) => {
               tab.id = index
               tab.path = tab.path.substring(0, tab.path.lastIndexOf("/") + 1) + index
            });

            let lastTab = state.tabs[state.tabs.length - 1]
            /* eslint-disable-next-line no-useless-escape */
            router.push(lastTab.path +"?"+ JSON.stringify(lastTab.query).replace(/[\{\}]|["]/g, "").replace(/[:]/g, "=").replace(/[,]/g, "&")).catch()
         }
      },
      verificarCredenciais : (state, credenciais) => {
         if (localStorage.username != undefined && localStorage.senha != undefined) {
            credenciais = {
               "usuario": localStorage.username.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join(""),
               "senha": localStorage.senha.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
            };
         }

         if (credenciais == undefined) {
            return
         } else {
            state.isCarregando = true
         }

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'loginAdmin',
            params: {
               email: credenciais.usuario,
               senha: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario = response.data
            state.tokenSessao = response.data.tokenSessao
            state.isLogado = true

            if (credenciais.manterConectado != false) {
               localStorage.username = credenciais.usuario.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.senha = credenciais.senha.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
            }

            router.push('/paginaInicial/'+ state.tabs.length)

         }).catch(function (error) {
            store.mutations.deslogar(state)

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else if (error.response.status == 403) {
                  Swal.fire({
                     icon: 'error',
                     title: 'Conta bloqueada!',
                     text: 'Número de tentativas excedido.'
                  });
               } else if (error.response.status == 409) {
                  Swal.fire({
                     icon: 'error',
                     title: 'Sem permissão!',
                     text: 'Nenhuma loja vinculada a esse usuário.'
                  });
               } else if (error.response.status == 406) {
                  Swal.fire({
                     icon: 'error',
                     title: 'Sem permissão!',
                     text: 'Nenhuma permissão concedida ao usuário.'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      deslogar : state => {
         state.dadosUsuario = null
         state.tokenSessao = null
         state.isLogado = false
         state.tabs = []

         localStorage.removeItem('username')
         localStorage.removeItem('senha')
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      }
   },
   actions: {
      imageError : ({commit}, e) => {
         commit('imageError', e)
      },
      addTabData : ({commit}, route) => {
         commit('addTabData', route)
      },
      attTabData : ({commit}, route) => {
         commit('attTabData', route)
      },
      deleteTabData : ({commit}, index) => {
         commit('deleteTabData', index)
      },
      verificarCredenciais : ({commit}, credenciais) => {
         commit('verificarCredenciais', credenciais)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      /* eslint-disable-next-line no-unused-vars */
      buscarCEP : ({commit}, cep) => {
         return new Promise((resolve, reject) => {
            Vue.prototype.$axios({
               method: 'get',
               url: 'https://viacep.com.br/ws/'+ cep +'/json/'

            }).then(response => {
               if (response.data.erro) {
                  reject(null)
               } else {
                  resolve(response.data)
               }
            }).catch(function () {
               reject(null)
            });
         })
      },
      /* eslint-disable-next-line no-unused-vars */
      base64toFile : ({commit}, base64Data) => {
         /* eslint-disable-next-line no-unused-vars */
         return new Promise((resolve, reject) => {
            if (base64Data.length == 0) {
               resolve([])
            }

            for (let i = 0; i < base64Data.length; i++) {
               if (String(base64Data[i]).startsWith('data:')) {
                  let byteCharacters = atob(base64Data[i].split(';base64,').pop());
                  let byteNumbers = new Array(byteCharacters.length);
            
                  for (let i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
            
                  let blob = new Blob([new Uint8Array(byteNumbers)], { type: 'image/png' });
                  base64Data[i] = new File([blob], 'imagem.png', { type: blob.type });
               }
   
               if (i == (base64Data.length - 1)) {
                  resolve(base64Data)
               }
            }
         })
      },
      /* eslint-disable-next-line no-unused-vars */
      formatValue : ({commit}, data) => {
         /* eslint-disable-next-line no-unused-vars */
         return new Promise((resolve, reject) => {
            let value = String(data.valor).replace(/\D/g, '');

            while (value.length <= data.digitos) {
               value = '0' + value;
            }

            resolve(parseInt(value.slice(0, -data.digitos)) +'.'+ value.slice(-data.digitos))
         })
      },
      deslogar : context => context.commit('deslogar')
   },
   modules: {
   }
}

export default new Vuex.Store(store);
